import React, { useRef } from "react";
import { useState, useEffect, useLayoutEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { changeEventRowSort, putJoin, deleteLeave, deleteKick, putConfirm, deleteCancel } from "../../api/Event";
import { Button, Table } from "react-bootstrap";
import "./event.css";
import Dropdown from "react-bootstrap/Dropdown";
import ShowMessage from "../util/ShowMessage/ShowMessage";
import MiniUser from "../util/MiniUser";
import { FaCircle } from "react-icons/fa6";

const Cell = ({ data, handleColumnClick, row, field, sides, isCanEdit, handlerChangeSide, handlerRefresh }) => {
    const handleClick = () => {
        if (isCanEdit) {
            if (data !== undefined) {
                handleColumnClick(data.value, data.id, "row");
            } else {
                handleColumnClick("", null, "row", row.id, field.id);
            }
        }
    };

    const DataRender = ({ data, row, field, sides }) => {
        if (field !== undefined && field.name === "side") {
            if (isCanEdit) {
                return (
                    <Dropdown className="align-self-center">
                        <Dropdown.Toggle variant="" id="dropdown-basic" style={row.EventSide ? { color: row.EventSide.color } : null}>
                            {row.EventSide ? row.EventSide.name : <span>Не выбрано</span>}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {sides.map((side) => (
                                <Dropdown.Item
                                    key={side.id}
                                    href=""
                                    style={{ color: side.color }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlerChangeSide(row.id, side.id);
                                    }}
                                >
                                    {side.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                );
            } else {
                return (
                    <span className="align-self-center">
                        <span style={row.EventSide ? { color: row.EventSide.color } : null}>{row.EventSide ? row.EventSide.name : <span>Не выбрано</span>}</span>
                    </span>
                );
            }
        } else {
            if (data !== undefined) {
                return <span className="align-self-center">{data.value}</span>;
            } else {
                return <span>&emsp;</span>;
            }
        }
    };

    return (
        <div
            className="h-100 d-flex flex-row"
            style={isCanEdit ? { cursor: "pointer" } : {}}
            key={data != undefined ? data.id : null}
            onClick={(e) => {
                if (field.name !== "side") {
                    handleClick();
                }
            }}
        >
            {data !== undefined ? <DataRender data={data} field={field} sides={sides} /> : <DataRender row={row} field={field} sides={sides} />}
        </div>
    );
};

function RowTable({ row, eventFields, handleColumnClick, handlerRefresh, handleContextMenuOpen, sides, isCanEdit, handlerChangeSide, user, isCanJoin }) {
    const ref = useRef(null);

    useEffect(() => {
        if (isCanEdit) {
            drag(drop(ref));
        }
    });

    const [, drop] = useDrop({
        accept: "row",
        drop: (item) => {
            changeEventRowSort(item.row.EventId, item.row.id, row.id).then(() => {
                handlerRefresh();
            });
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "row",
        item: { row },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const handlerJoin = (rowId) => {
        putJoin(rowId)
            .then(() => {
                handlerRefresh();
            })
            .catch((err) => {
                ShowMessage({ type: "danger", message: "Не удалось занять роль." });
            });
    };

    const handlerLeave = (eventId) => {
        deleteLeave(eventId).then(() => {
            handlerRefresh();
        });
    };

    const handlerKick = (rowId) => {
        deleteKick(rowId).then(() => {
            handlerRefresh();
        });
    };

    const handlerConfirm = (rowId, userId) => {
        putConfirm(rowId, userId).then(() => {
            handlerRefresh();
        });
    };

    const handlerCancel = (rowId, userId) => {
        deleteCancel(rowId, userId).then(() => {
            handlerRefresh();
        });
    }

    const indicatorClass = () => {
        //Подтвержден участник
        if (row.User) {
            return "fa-lg text-success";
        }
        //Ожидается подтверждение по строке
        if (row.EventAppointsPendings.length > 0 && isCanEdit) {
            return "fa-lg text-warning";
        }
        //Можно занять
        return "fa-lg text-gray";
    };

    return (
        <div
            ref={ref}
            item={row}
            key={row.id}
            className="row"
            onContextMenu={(e) => {
                handleContextMenuOpen(e, null, "row", row.id);
            }}
        >
            <div className="col border-bottom p-2 d-flex flex-column">
                <div className="h-100 d-flex flex-row w-100">
                    <div className="me-4 d-flex align-items-center h-100">
                        <FaCircle className={indicatorClass()} />
                    </div>
                    {row.User ? (
                        <div className="w-100">
                            {row.User.id !== user.id ? (
                                <Dropdown className="align-self-center">
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                        <MiniUser user={row.User} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {isCanEdit ? (
                                            <Dropdown.Item
                                                onClick={(e) => {
                                                    handlerKick(row.id);
                                                }}
                                            >
                                                Освободить слот
                                            </Dropdown.Item>
                                        ) : (
                                            <Dropdown.Item>Пожаловаться</Dropdown.Item>
                                        )}
                                        <Dropdown.Item>Подробнее</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : ( <div className=""><div className="btn align-self-center"><MiniUser user={row.User} /></div></div> )}
                        </div>
                    ) : (
                        <div className="w-100">
                            {row.EventAppointsPendings ? (
                                <div>
                                    {row.appointusers_id.includes(user.id) ? (
                                        null
                                    ) : (
                                        <div>
                                            {isCanEdit ? (
                                                <div>
                                                    {row.EventAppointsPendings.map((apppointment) => (
                                                        <Dropdown className="align-self-center" key={apppointment.id}>
                                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                <MiniUser user={apppointment.User} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    onClick={(e) => {
                                                                        handlerConfirm(row.id, apppointment.User.id);
                                                                    }}
                                                                >
                                                                    Принять
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={(e) => {
                                                                        handlerCancel(row.id, apppointment.User.id);
                                                                    }}
                                                                >Отклонить</Dropdown.Item>
                                                                <Dropdown.Item>Подробнее</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div>

                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ) : null}                          
                        </div>
                    )}
                </div>
            </div>
            {eventFields.map((field) => (
                <div key={field.id} className="col border-bottom p-2 d-flex flex-column" scope="row">
                    {row.EventRowData.filter((row) => row.EventFieldId === field.id).map((data) => (
                        <Cell key={data.id} handleColumnClick={handleColumnClick} data={data} field={field} sides={sides} isCanEdit={isCanEdit} handlerChangeSide={handlerChangeSide} handlerRefresh={handlerRefresh} />
                    ))}
                    {row.EventRowData.filter((row) => row.EventFieldId === field.id).length === 0 && <Cell handleColumnClick={handleColumnClick} row={row} field={field} sides={sides} isCanEdit={isCanEdit} handlerChangeSide={handlerChangeSide} handlerRefresh={handlerRefresh} />}
                </div>
            ))}
            <div className="col border-bottom p-2 d-flex flex-column align-items-center h-100" scope="row">      
                <div className="d-flex align-items-center h-100 w-100">
                {isCanJoin === true ? (                         
                    <Button 
                        onClick={(e) => {
                            handlerJoin(row.id);
                        }}
                        className="btn btn-primary"
                        >
                        Занять слот
                    </Button>
                ) : ( null )}   
                {row.appointusers_id.includes(user.id) ? (
                    <Button
                        onClick={(e) => {
                            handlerJoin(row.id);
                        }}
                        className="btn btn-danger"
                        onClick={(e) => {
                            handlerLeave(row.EventId);
                        }}
                    >
                        Выписаться
                    </Button>
                ) : ( null )}
                {row.User && row.User.id === user.id ? (
                    <Button
                        onClick={(e) => {
                            handlerJoin(row.id);
                        }}
                        className="btn btn-danger"
                        onClick={(e) => {
                            handlerLeave(row.EventId);
                        }}
                    >
                        Выписаться
                    </Button>
                ) : ( null )}
                {row.EventAppointsPendings.map((apppointment) => (
                    null
                    /*<div>
                        {apppointment.User.id === user.id ? (
                            <Button
                                onClick={(e) => {
                                    handlerJoin(row.id);
                                }}
                                className="btn btn-danger"
                            >
                                Отклонить заявку
                            </Button>
                        ) : null}
                    </div>*/
                ))}                
                </div>
            </div>
        </div>
    );
}

export default RowTable;
