import React, { useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useLayoutEffect} from "react";
import { putSideEdit, getAddSide, deleteSide} from '../../api/Event';
import ShowMessage from '../util/ShowMessage/ShowMessage.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { SketchPicker } from 'react-color';
import './sides.css';

const Sides = ({sides, handlerRefreshTable, eventId}) => {
    const sketchWindow = useRef(null);

    const [nameValiue, setNameValue] = useState();
    const [idValue, setIdValue] = useState();
    const [colorValue, setColorValue] = useState('#ffffff');

    const handleEdit = () => {
        putSideEdit({id: idValue, name: nameValiue}).then(() => {
            handlerRefreshTable();
            //ShowMessage({delay: 5000, message: 'Тестовое сообщение'});

        }).catch((err) => {
            if (err.response.data.error.name[0]){
                ShowMessage({type: 'danger', delay: 3500, message: err.response.data.error.name[0]});
            }else{
                ShowMessage({type: 'danger', delay: 3500, message: 'Не удалось изменить сторону. Неизвестная ошибка.'});
            }
        });
    }

    const handlerAdd = () => {
        getAddSide({id: eventId}).then(() => {
            handlerRefreshTable();
        })
    }

    const handlerDelete = (id) => {
        deleteSide({id: id}).then(() => {
            handlerRefreshTable();
        })
    }

    const hadlerColorPick = () => {
        sketchWindow.current.style.display = 'none';
        putSideEdit({id: idValue, color: colorValue.hex}).then(() => {
            handlerRefreshTable();
        }).catch((err) => {
            if (err.response.data.error && err.response.data.error.color[0]){
                ShowMessage({type: 'danger', delay: 3500, message: err.response.data.error.name[0]});
            }else{
                ShowMessage({type: 'danger', delay: 3500, message: 'Не удалось изменить сторону. Неизвестная ошибка.'});
            }
        });;
    }

    return (
        <div>
            <div ref={sketchWindow} className='sketch-container' style={{display: 'none'}}>
                <SketchPicker color={colorValue} onChange={(e)=> {setColorValue(e)}}/>
                <div className="d-grid gap-2 w-100">
                    <button onClick={hadlerColorPick} className='btn btn-primary'>Применить</button>
                    <button onClick={(e) => {sketchWindow.current.style.display = 'none';}} className='btn btn-danger'>Закрыть</button>
                </div>
            </div>
            {sides.map(side => (
                <div key={side.id} className='d-flex'>
                    <div 
                        className='text-danger'
                        style={{width: '20px', height: '20px', cursor: 'pointer'}}
                        onClick={() => {handlerDelete(side.id)}}
                        >
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                    <div 
                        onClick={(e) => {setIdValue(side.id); setColorValue(side.color); sketchWindow.current.style.display = 'block';}}
                        className='me-2 border rounded'
                        style={{backgroundColor: side.color, width: '20px', height: '20px', cursor: 'pointer'}}>
                    </div>
                    <div 
                        id={`div_${side.id}`} 
                        style={{cursor: "pointer"}}
                        onClick={(e) => {
                            setNameValue(side.name); 
                            setIdValue(side.id); e.target.style.display = 'none'; 
                            document.getElementById(`input_${side.id}`).style.display = 'block';
                            document.getElementById(`input_${side.id}`).focus();
                        }}
                        >
                            {side.name}
                    </div>
                    <input 
                        key={side.id} 
                        id={`input_${side.id}`}
                        type="text" 
                        className="form-control" 
                        style={{display: 'none'}}
                        value={nameValiue} 
                        onChange={(e) => {setNameValue(e.target.value)}}
                        onKeyDown={(e) => {if (e.key === 'Enter') {                            
                            handleEdit(); 
                            e.target.style.display = 'none';
                            document.getElementById(`div_${side.id}`).style.display = 'block';
                        }}}
                        onBlur={(e) => {
                            handleEdit(); 
                            e.target.style.display = 'none';
                            document.getElementById(`div_${side.id}`).style.display = 'block';
                        }}
                        />

                </div>
            ))}
            <a href="#" onClick={(e) => {e.preventDefault(); handlerAdd();}} >Добавить сторону</a>
        </div>
    )
}

export default Sides;