import React, { useRef } from 'react';
import { useEffect, useState } from "react";

//import UserPage from './User/UserPage';
import { Container, Navbar, NavDropdown, Nav, Row, Col } from "react-bootstrap";
import { Dropdown, Collapse} from 'bootstrap';
import { PeopleFill, Controller, BoxArrowRight } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import UserPage from './User/UserPage';
import GamePage from './Game/GamePage';
import SideBar from './components/SideBar';

const AdminPage = ({page}) => {
    document.title = "Админская";
    const [loading, setLoading] = useState(false);
    const [selectedPage, setSelectedPage] = useState();

    useEffect(() => {
        setPage(page);
    }, []);

    const setPage = (num) => {
        switch (num){
            case 0:
                setSelectedPage(
                    () => {
                        return (
                            <UserPage setLoading={setLoading}/>
                        );
                    }
                );
                break;
            case 1:
                setSelectedPage(
                    () => {
                        return (
                            <GamePage setLoading={setLoading}/>
                        );
                    }
                );
                break;                
        }
    }

    const handleChangePage = (num) => {
        setPage(num);
    }

    const navigate = useNavigate()

    return (
        <SideBar page={selectedPage} handleChangePage={handleChangePage} loading={loading}/>
    );
}

export default AdminPage;