import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const MiniUser = ({ user }) => {
    const [avatar, setAvatar] = useState();
    const [asset, setAsset] = useState("5.0");

    useEffect(() => {
        if (user.avatar) {
            setAvatar(`${process.env.REACT_APP_SERVER}${user.avatar}`);
        } else {
            setAvatar("/images/avatar_bw_op.png");
        }
    });

    return (
        <div className="d-flex justify-content-start align-items-center">
            <div className="d-block">
                <img style={{ height: "40px" }} className="rounded-circle border me-3" src={avatar} />
            </div>
            <div>
                <div className="fw-bold">{user.name}</div>
                {asset ? <div className="small">{asset}</div> : <></>}
            </div>
        </div>
    );
};

export default MiniUser;
