import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { postNotificationRead } from "../../api/Notification";
import moment from 'moment';

const Notification = ({notifications, handlerNotifyReload}) => {
    useEffect(() => {
        console.log(notifications);
    }, [notifications]);

    const readHandler = (id) => {
        postNotificationRead(id).then(() => {
            handlerNotifyReload();
        })
    }

    return (
        <div>
            {notifications.map(notify => (
                <div 
                    onClick={(e) => {readHandler(notify.id)}}
                    key={notify.id}
                    className={`d-block m-2 border rounded-2 ${notify.status === 'normal' ? 'border-success' : notify.status === 'danger' ? 'border-danger' : 'border-warning'}`} 
                    style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>
                        <div className="m-2" style={{overflow: "break-word"}} >{notify.message}</div>
                    <p className="mx-2 mb-1 fw-lighter" style={{fontSize: "0.7rem"}}>{moment(notify.createdAt).format('DD.MM.YYYY HH:mm')}</p>
                </div>
            ))}
                     
            <Link to="/notifications" className="d-block m-2" style={{cursor: 'pointer'}}>
                <div className="m-2">Показать все...</div>
            </Link>                     
        </div>
    )
}

export default Notification;