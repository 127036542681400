import React from 'react';
import { useState } from "react";

//import UserPage from './User/UserPage';
import { Container, Navbar, NavDropdown, Nav, Row, Col } from "react-bootstrap";
import { Dropdown, Collapse} from 'bootstrap';
import { PeopleFill, Controller, BoxArrowRight } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import LodingIndicator from '../../../component/layout/LodingIndicator';

const SideBar = ({page, handleChangePage, loading}) => {
    const navigate = useNavigate();

    return (
        <div className="container-fluid bg-light">
            <LodingIndicator loading={loading} />
            <div className="row flex-nowrap">
                <div className="col-auto col-md-3 col-xl-2 px-sm-1 px-0 bg-secondary">
                    <div className="d-flex flex-column align-items-center align-items-sm-start px-0 pt-2 text-white min-vh-100">
                        <div className="d-flex align-items-center mt-1 ms-3 mb-md-0 me-md-auto text-white text-decoration-none">
                            <h4 className="mt-2 fs-5 d-none d-sm-inline">Админка</h4>
                        </div>
                        <hr className='w-100'/>
                        <ul className="nav nav-pills px-3 flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                            <li>
                                <a href="#submenu1" data-bs-toggle="collapse" className="text-white btn-toggle nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">Данные</span> 
                                </a>
                                <ul className="collapse nav flex-column ms-1" id="submenu1" data-bs-parent="#menu">
                                    <li className="w-100">
                                        <a href="#" onClick={(event) => {event.preventDefault(); handleChangePage(0);}} className="text-white nav-link px-0"> <span className="ps-4 d-none d-sm-inline">Пользователи</span></a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(event) => {event.preventDefault(); handleChangePage(1);}} className="text-white nav-link px-0"> <span className="ps-4 d-none d-sm-inline">Игры</span></a>
                                    </li>
                                </ul>
                            </li>    
                            <li>
                                <a href="#"onClick={(event) => {event.preventDefault(); navigate('/');}} className="text-white nav-link px-0 align-middle">Bыход</a>
                            </li>                                               
                        </ul>
                    </div>
                </div>
                <div className='col'>
                    {page}
                </div>
            </div>
        </div>
    );
}

export default SideBar;