import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import MiniUser from "../util/MiniUser";

function EventCard({ name, desc, image, id, user }) {
    const navigate = useNavigate();

    return (
        <Card style={{ minHeight: "530px" }} className="rounded-4">
            <div style={{ maxHeight: "200px", overflow: "hidden" }}>
                <Card.Img variant="top" src={process.env.REACT_APP_SERVER + image} style={{ objectFit: "cover", height: "300px" }} className="rounded-top-4" />
            </div>
            <Card.Body style={{ height: "100px" }} className="border-top">
                <MiniUser user={user}></MiniUser>
                <hr />
                <Card.Title>{name}</Card.Title>
                <Card.Text className="text-truncate-5">{desc}</Card.Text>
            </Card.Body>
            <Card.Footer className="rounded-bottom-4 border-top-0 p-0">
                <Button className="w-100 rounded-bottom-4 rounded-top-0 py-3 text-white" variant="primary" onClick={() => navigate("/event/" + id)}>
                    Подробнее
                </Button>
            </Card.Footer>
        </Card>
    );
}

export default EventCard;
