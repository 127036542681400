import React, { useRef } from "react";
import Header from "../layout/Header";
import { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getEvent, removeEvent, postEventField, postCreateEventField, postCreateEventRow, getRemoveEventField, putEventRow, getRemoveEventRow, putEditEvent, putImageEvent, postImageBrif, postCopyRow } from "../../api/Event";
import { getUser } from "../../api/User";
import { Button, Table } from "react-bootstrap";
import ModalConfirm from "../util/ModalConfirm";
import ContextMenu from "./ContextMenu";
//import EditModal from './EditModal';
import { useDrag, useDrop } from "react-dnd";
import io from "socket.io-client";
import errorParser from "../../utils/ErrorParser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import HeaderTable from "./HeaderTable";
import RowTable from "./RowTable";
import "./event.css";
import Accordion from "react-bootstrap/Accordion";
import Sides from "./Sides";
import { isEditable } from "@testing-library/user-event/dist/utils";
import ShowMessage from "../util/ShowMessage/ShowMessage.js";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

//New
import "./Event.scss";
import MiniUser from "../util/MiniUser";
import { FaCircle } from "react-icons/fa6";

const socket = io(process.env.REACT_APP_SOCKET_SERVER, {
    autoConnect: false,
});

const Event = () => {
    const navigate = useNavigate();

    const [eventData, setEventData] = useState({ desc: "" });
    const [eventUser, setEventUser] = useState({});

    const [socketConnected, setSocketConnected] = useState(false);
    const [socketHash, setSocketHash] = useState("");

    //TODO: Отказатся от использования этих данных
    const { id } = useParams();
    const [event, setEvent] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
        refreshTable();
    }, []);

    useEffect(() => {
        if (user && !socketHash) {
            socket.engine.on("connect", () => {
                const datetime = new Date();
                setSocketHash(Math.floor(datetime.getTime() / 1000) + `${user.id}`);
                setSocketConnected(true);
            });
            socket.engine.connect();
        }
    }, [user]);

    useEffect(() => {
        if (socketHash) {
            socket.on("change-event", (data) => {
                if (data.hash !== `${socketHash}` && event.id === data.id) {
                    refreshTable(false);
                }
            });
        }
    }, [socketHash]);

    const acessName = ["Закрытый", "Открытый", "По ссылке"];

    const refreshTable = (emit = true) => {
        getUser().then((user) => {
            getEvent(id)
                .then((data) => {
                    if (emit) {
                        if (socketConnected) {
                            socket.timeout(100).emit("change-event", { id: event.id, hash: socketHash });
                        }
                    }

                    setEventData(data);
                    setEventUser(user);
                })
                .catch((err) => {
                    alert("Непредвиденная ошибка");
                });
        });
    };

    return (
        <div>
            <Header />

            <div className="container-fluid main-img-background" style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER + eventData.image})` }}></div>

            <div className="container main-image-container mt-5">
                <div className="row">
                    <div className="col-3 pe-5 d-none d-lg-block">
                        <div>
                            <img src={process.env.REACT_APP_SERVER + eventData.image} alt="" className="main-img img-fluid rounded-4 shadow" />
                        </div>
                        <div className="mt-3">
                            <Link to={`/event/${eventData?.id}/edit`} className="me-3 btn btn-link text-decoration-none">
                                Редактировать
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-9">
                        <div>{eventUser ? <MiniUser user={eventUser}></MiniUser> : <></>}</div>
                        <h1 className="mt-4">{eventData.name}</h1>
                        {eventData.dateStart ? (
                            <p className="lead">
                                <span className="me-2">{new Date(eventData.dateStart * 1000).toLocaleDateString("ru-RU")}</span>
                                <span>{new Date(eventData.dateStart * 1000).toLocaleTimeString("ru-RU")}</span>
                            </p>
                        ) : (
                            <></>
                        )}

                        <p>
                            {
                                //todo: добавить идентификатор игры
                            }
                            <span className="badge rounded-pill text-bg-primary text-white me-1">DCS</span>
                            {eventData.access ? <span className="badge rounded-pill text-bg-primary text-white me-1">{acessName[eventData.access]}</span> : <></>}
                            {eventData.changeside ? <span className="badge rounded-pill text-bg-primary text-white me-1">Нельзя сменить сторону</span> : <></>}
                            {eventData.confirmation ? <span className="badge rounded-pill text-bg-primary text-white me-1">Подтверждение участия</span> : <></>}
                        </p>
                        {eventData.desc.length > 0 ? <p>{eventData.desc}</p> : <></>}
                        {eventData.EventSides?.length > 0 ? (
                            <div className="my-4">
                                <h3>Стороны</h3>
                                {eventData.EventSides.map((side) => (
                                    <div className="d-flex align-items-center">
                                        <div className="me-2" style={{ color: side.color }}>
                                            <FaCircle />
                                        </div>
                                        <div className="mt-1">{side.name}</div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <></>
                        )}
                        {eventData.descFull?.length > 0 ? (
                            <div className="mb-4">
                                <h3>Брифинг</h3>
                                <div dangerouslySetInnerHTML={{ __html: eventData.descFull }} />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                {eventData.EventRows?.length > 0 ? (
                    <div className="row mb-5">
                        <div className="col-12">
                            <h3>Слоты</h3>
                            {eventData.EventRows?.map((row, index) => (
                                <div key={index} className="row my-2 align-items-center">
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                    <div className="col-6 text-end justify-content-center d-flex">{row.User ? <MiniUser user={row.User}></MiniUser> : <button className="btn btn-primary text-white rounded-4 w-100">Занять слот</button>}</div>
                                    <div className="col-6">
                                        {row.EventSide ? (
                                            <p className="small mb-0" style={{ color: row.EventSide?.color }}>
                                                {row.EventSide?.name}
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        {row.EventRowData[0]?.value ? row.EventRowData[0]?.value : <span className="text-muted">Нет описания, у организатора творческий кризис</span>}
                                    </div>
                                    <div className="col-3"> </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default Event;
