import { Navigate, useLocation } from "react-router-dom";
import { getToken } from "../../storage/Token";
import { getUser } from "../../storage/User";

const AuthRequire = ({ children }) => {
    let location = useLocation();
    const token = getToken();
    if (token == undefined) {
        return <Navigate to={"/login"} state={{ from: location }} replace />;
    }
    return <>{children}</>;
};

export default AuthRequire;