import Modal from 'react-bootstrap/Modal';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { postCreate, putGame } from "../../../api/Game";
import { useEffect, useState } from 'react';
import errorParser from '../../../utils/ErrorParser';
import { getGame } from '../../../api/Game';
import {FaTrash} from 'react-icons/fa';

let nextId = 0;

const GameEditModal = ({showModal, handleCloseModal, idGameEdit, refresh}) => {
    const [nameError, setNameError] = useState();
    const [name, setName] = useState('');
    const [id, setId] = useState();
    const [fields, setFields] = useState([]);

    useEffect(() => {
        fields.splice(0, fields.length);
        setFields([]);
        setNameError();
        if (idGameEdit !== true && idGameEdit !== undefined){
            getGame(idGameEdit).then((game) => {
                setName(game.data.name);
                const arr = [];
                for(const key in game.data.Gamefields){
                    arr.push({id: nextId++, name: game.data.Gamefields[key].name});
                }
                setFields(arr);
                refresh();
            });
        }else{
            setName('');
        }
    }, [showModal]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const name = data.get('name');
        if (idGameEdit !== true){
            putGame(idGameEdit, name, fields).then(() => {
                handleCloseModal();
                setNameError();
            }).catch((err) => {
                errorParser(err, {
                    name: setNameError,
                });
            })
        }else{
            postCreate(name).then((res) => {
                handleCloseModal();
                setNameError();
            }).catch((err) => {
                errorParser(err, {
                    name: setNameError,
                });
            });
        }
    }
    
    const addField = (name) => {
        if (name.length > 0){
            setFields([
                ...fields,
                {id: nextId++, name: name}
            ]);
        }
    }

    const removeFieldHandler = (event) => {
        for (const key in fields) {
            if (fields[key].id == event.target.id){
                fields.splice(key, 1);
            }
        }

        setFields([
            ...fields
          ]);
    }

    const fieldKeyDownHandler = (event) => {
        if (event.key == 'Enter'){            
            event.preventDefault();
            addField(event.target.value);
            event.target.value = '';
        }
    }

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                {idGameEdit === true && <Modal.Title>Добавить игру</Modal.Title>}
                {idGameEdit !== true && <Modal.Title>Редактировать игру</Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                <Form id='game-form' onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                            Наименование проекта
                        </Form.Label>
                        <Form.Control isInvalid={ nameError } onChange={(event) => {setName(event.target.value)}} value={name} name="name" type="text" placeholder="Наименование проекта" />
                        <Form.Control.Feedback type="invalid">
                            {nameError}
                        </Form.Control.Feedback>
                    </Form.Group>               
                    <hr/>
                    {fields.map((field, index) => 
                        <div key={index} className='d-flex my-2 align-items-center'>
                            <a id={field.id} href='#' onClick={removeFieldHandler} style={{textDecoration: 'none'}} className='me-2 text-danger'>&times;</a>
                            <h5 className='my-0' key={field.id}>{field.name}</h5>
                        </div>
                    )}
                    <Form.Group className="mb-3 d-flex">
                        <Form.Control onKeyDown={fieldKeyDownHandler} id="add_field_input" name="name" type="text" placeholder="Наименование нового поля" />
                    </Form.Group>                    
     
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleCloseModal}>
                Закрыть
                </Button>
                <Button variant="primary"  type='submit' form='game-form'>
                    Подтвердить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default GameEditModal;