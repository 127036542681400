import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({page, count, limit, setPage}) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        const pages = Math.ceil(count/limit);
        if (page + 1 > pages && pages != 0) { 
            setPage(pages) 
        }
        let array = [];
        array.push(
            <Pagination.First key={-2} disabled={page === 1} onClick={() => setPage(1)}/>
        );
        array.push(
            <Pagination.Prev key={-1} disabled={page === 1} onClick={() => setPage(page - 1)}/>    
        )
        for (let number = page-3; number <= page+3; number++) {

            if (number > 0 && number <= pages){
                array.push(
                    <Pagination.Item key={number} active={page === number} onClick={() => setPage(number)}>
                        {number}
                    </Pagination.Item>,
                );
            }
        }        
        array.push(
            <Pagination.Next key={'a'} disabled={page >= pages} onClick={() => setPage(page + 1)}/>
        );
        array.push(
            <Pagination.Last key={'b'} disabled={page >= pages} onClick={() => setPage(pages)}/>    
        )
            
                
        setItems(array);
    }, [page, count]);

    return (
        <div>
            {count > 0 && count > limit &&
                <nav aria-label="" className="my-3 d-flex justify-content-end">
                <Pagination>
                    {items}
                </Pagination>
                </nav>
            }
        </div>
    )
}
export default PaginationComponent;