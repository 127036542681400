import { apiClient } from "./ApiConfig";

export async function getGames(limit, offset, name){
    const res = (await apiClient.get("/games", {
        params: {
            limit: limit,
            offset: offset,
            name: name
        }
    }));

    res.count = res.headers["x-total-count"];
    return res;
}

export async function postCreate(name) {
    const path = "/game/create";
    return apiClient.post(path, {
        'name': name,
    });
}

export async function getGame(id) {
    const path = "/game/"+id;
    const res = await apiClient.get(path);
    return res;
}

export async function putGame(id, name, fields){
    const path = "/game/"+id;
    const res = await apiClient.put(path, {
        'name': name,
        'fields': fields
    });
    return res;
}

export async function deleteGame(id){
    const path = "/game/"+id;
    const res = await apiClient.delete(path);
    return res;
}