import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Login from "./pages/user/login/Login";
import Create from "./pages/user/create/Create";
import Error from "./pages/error/Error";
import MainPage from './pages/MainPage';
import AdminMainPage from './pages/admin/MainPage';

import AuthRequire from "./utils/auth/AuthRequire";
import AuthNo from "./utils/auth/AuthNo";
import AuthLogOut from "./utils/auth/AuthLogOut";
import Profile from './pages/user/profile/Profile';
import Event from './pages/event/Event';
import EventEdit from './pages/event/EventEdit';
import Notifications from './pages/notifications/list/Notifications';
import ShowMessageWindow from './pages/util/ShowMessage/ShowMessageWindow';

import { setDefaultTheme } from "./utils/ThemeSwitcher";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getUser } from "../src/api/User";

import io from "socket.io-client";

const socket = { engine: io(process.env.REACT_APP_SOCKET_SERVER, {
    autoConnect: false,
})};

setDefaultTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));

getUser().then((user) => {
    if (user && !socket.hash) {
        socket.engine.on("connect", () => {
            const datetime = new Date();
            socket.hash = (Math.floor(datetime.getTime() / 1000) + `${user.id}`);
            //setSocketConnected(true);
        });
        socket.engine.connect();
    }    
});

const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthNo><Login /></AuthNo>,
        errorElement: <Error />,
        title: "Вход",
    },
    {
        path: "/login",
        element: <AuthNo><Login /></AuthNo>,
        title: "Вход",
    },
    {
        path: "/discord-auth/:token",
        element: <AuthNo><Login /></AuthNo>,
    },
    {
        path: "/create",
        element: <AuthNo><Create /></AuthNo>,
    },
    {
        path: "/site",
        element: <AuthRequire><MainPage socket={socket}/></AuthRequire>,
    },
    {
        path: "/logout",
        element: <AuthLogOut></AuthLogOut>
    },
    {
        path: "/admin",
        element: <AuthRequire><AdminMainPage /></AuthRequire>
    },
    {
        path: "/admin/user",
        element: <AuthRequire><AdminMainPage page={0}/></AuthRequire>
    },    
    {
        path: "/admin/game",
        element: <AuthRequire><AdminMainPage page={1}/></AuthRequire>
    },    
    {
        path: "/profile",
        element: <AuthRequire><Profile /></AuthRequire>
    },
    {
        path: "/event/:id",
        element: <AuthRequire><EventEdit socket={socket}/></AuthRequire>,
    },
    {
        path: "/notifications",
        element: <AuthRequire><Notifications socket={socket}/></AuthRequire>,
    },    
    /*{
        path: "/event/:id/edit",
        element: <AuthRequire><EventEdit /></AuthRequire>,
    }*/
]);

root.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <ShowMessageWindow/>
            <RouterProvider router={router} />
        </DndProvider>
    </React.StrictMode>
);

reportWebVitals();
