
import React, { useState, useRef, useEffect } from 'react';

const ContextMenu = ({ x, y, onClose, actions }) => {
    const menuRef = useRef(null);
  
    // Закрываем контекстное меню при монтировании компонента, чтобы избежать открытия с предыдущего вызова.
    useEffect(() => {
      const handleDocumentClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
          onClose();
        }
      };
  
      document.addEventListener('click', handleDocumentClick);
  
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, [onClose]);
  
    return (
      <div
        ref={menuRef}
        style={{
          position: 'fixed',
          top: y,
          left: x,
          backgroundColor: 'white',
          border: '1px solid #ddd',
          padding: '8px',
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
          zIndex: 1000,
        }}
      >
        {actions.map((action) => 
            <div key={action.name} role="button" onClick={action.action}>{action.name}</div>
        )}
      </div>
    );
  };

export default ContextMenu;