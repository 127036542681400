import { useNavigate, Link } from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";
import { getUser } from "../../api/User";
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";
import CreateEventModal from "../event/CreateEventModal";
import { setTheme, clearTheme, getPreferredTheme } from "../../utils/ThemeSwitcher";
import "../../scss/bootstrap.scss";
import "../../scss/style.scss";
import "./Header.scss";
import { FaBell } from 'react-icons/fa6';
import Notification from './Notification';
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import {getNotificationShort} from "../../api/Notification";

const Header = ({ eventRefreshHandler, socket }) => {
    const navigate = useNavigate();
    //const location = useLocation();
    const [username, setUsername] = useState("");
    const [userAvatar, setUserAvatar] = useState(null);
    const [mainMenuOpen, setMainMenuOpen] = useState(null);
    const [superAdmin, setSuperAdmin] = useState(0);
    const [createEventModalShow, setCreateEventModalShow] = useState(false);
    const [lightTheme, setLightTheme] = useState(getPreferredTheme());
    const [notifyAlert, setNorifyAlert] = useState(false);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        getUser()
            .then((user) => {
                setUsername(user.name);
                setSuperAdmin(user.superadmin);
                socket.engine.off("notification");
                socket.engine.on("notification", (data) => {
                    if (data.user_id == user.id){
                        handlerNotifyReload();
                    }
                });
                handlerNotifyReload();

                if (user.avatar) {
                    setUserAvatar(`${process.env.REACT_APP_SERVER}${user.avatar}`);
                } else {
                    setUserAvatar(`/images/avatar-min.png`);
                }
            })
            .catch(() => {});
    }, []);

    const handleMenuClose = () => {
        setMainMenuOpen(null);
    };

    const handleMenuClick = (event) => {
        setMainMenuOpen(event.currentTarget);
    };

    const handleMenuLogout = (event) => {
        navigate("/logout");
    };

    const handlerMenuAdmin = (event) => {
        navigate("/admin");
    };

    const handlerCreateEventClose = (event) => {
        setCreateEventModalShow(false);
    };

    const handlerNotifyReload = () => {
        getNotificationShort().then(({data}) => {
            if (data.length > 0){
                setNorifyAlert(true);
            }else{
                setNorifyAlert(false);
            }
            setNotifications(data);
        });
    }

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <CreateEventModal showModal={createEventModalShow} handleCloseModal={handlerCreateEventClose} eventRefreshHandler={eventRefreshHandler} />

            <div className="container">
                <Link to={"/"}>
                    <img style={{ maxHeight: "30px" }} src="/images/logotype-horizontal-2.svg"></img>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle active fw-bold"
                                data-bs-toggle="dropdown" 
                                aria-current="page"
                                href="#"
                                /*onClick={(event) => {
                                    event.preventDefault();
                                    setCreateEventModalShow(true);
                                }}*/
                            >
                                <FaBell />
                                { notifyAlert && (
                                    <span className="notification-indicator"></span>
                                )}
                            </a>
                            <div className="dropdown-menu rounded-3 p-2 shadow border-0" style={{width: '320px'}}>
                                <Notification notifications={notifications} handlerNotifyReload={handlerNotifyReload}/>
                            </div>                            
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link active fw-bold"
                                aria-current="page"
                                href="#"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setCreateEventModalShow(true);
                                }}
                            >
                                Создать событие
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={userAvatar} alt="" className="border rounded-5 avatar me-2" />
                                {username}
                            </a>
                            <ul className="dropdown-menu rounded-3 p-2 shadow border-0">
                                {lightTheme == "dark" && (
                                    <li>
                                        <button
                                            className="dropdown-item rounded-3 px-3 py-2"
                                            onClick={() => {
                                                setTheme("light");
                                                setLightTheme("light");
                                            }}
                                        >
                                            Светлая тема
                                        </button>
                                    </li>
                                )}
                                {lightTheme == "light" && (
                                    <li>
                                        <button
                                            className="dropdown-item rounded-3 px-3 py-2"
                                            onClick={() => {
                                                setTheme("dark");
                                                setLightTheme("dark");
                                            }}
                                        >
                                            Темная тема
                                        </button>
                                    </li>
                                )}

                                <li>
                                    <button
                                        className="dropdown-item rounded-3 px-3 py-2"
                                        onClick={() => {
                                            clearTheme();
                                        }}
                                    >
                                        По умолчанию
                                    </button>
                                </li>

                                {superAdmin == 1 && (
                                    <li>
                                        <Link to={"/admin"} className="dropdown-item rounded-3 px-3 py-2">
                                            Администрирование
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <Link to={"/profile"} className="dropdown-item rounded-3 px-3 py-2">
                                        Профиль
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/logout"} className="dropdown-item rounded-3 px-3 py-2">
                                        Выход
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header;
