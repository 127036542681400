import { useAsyncEffect, useReactive, useSetState } from "ahooks";
import React, { useEffect, useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { getGames, deleteGame } from "../../../api/Game";
import { useNavigate } from "react-router-dom";
import LodingIndicator from "../../../component/layout/LodingIndicator";
import GameEditModal from "./GameEditModal";
import PaginationComponent from "../components/PaginationComponent";
import FindString from "../components/FindString";
import Dropdown from 'react-bootstrap/Dropdown';
import { FaAlignJustify } from 'react-icons/fa';

  const GamePage = ({setLoading}) => {
    const [games, setGames] = useState([]);
    const [showGameEditModal, setShowGameEditModal] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [idGameEdit, setIdGameEdit] = useState();
    const [findString, setFindString] = useState('');
    const navigate = useNavigate();    

    useEffect(() => {
      navigate('/admin/game', {replace: true});
      refresh();
    },[page, findString]);
    const refresh = () => {
      const fetch = async () => { 
        setLoading(true);
        const res = await getGames(limit, (page-1)*limit, findString); 
        setCount(res.count);

        setGames(res.data);
        setLoading(false);
        return;
      }

      fetch();
    }

    const handleCloseModal = () => {
      setShowGameEditModal(false);
    }

    const handleShowModal = (id) => {
      setIdGameEdit(id);
      setShowGameEditModal(true);
    }

    const handlerDelete = (event) => {
      deleteGame(event.target.id).then(() => {
        refresh();
      })
    }

    function CustomComponent({children}) {
      let avatar = "/images/avatar-min.png";

      if (children.avatar != null){
        avatar = process.env.REACT_APP_SERVER+children.avatar;
      }

      return (
          <li className="list-group-item bg-light d-flex" >
            <div className="d-flex align-items-center" >
              <span style={{cursor: 'pointer'}} onClick={() => {handleShowModal(children.id)}}><h5 className="m-0">{children.name}</h5></span>
            </div>
            <div className="ms-auto">
              <Dropdown className="">
                <Dropdown.Toggle title="test" variant="" id="collasible-nav-dropdown">
                  <FaAlignJustify/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#" id={children.id} onClick={handlerDelete}>Удалить</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>            
            </div>
          </li>
          
      );
    }

    return (
      <div className="mt-4">
        <GameEditModal refresh={refresh} showModal={showGameEditModal} handleCloseModal={handleCloseModal} idGameEdit={idGameEdit}/>
        <FindString setFindString={setFindString} findString={findString} refreshHandler={refresh}/>
        <Button onClick={(event) => {handleShowModal(true)}}>Добавить игру</Button>
        <ListGroup className="mt-3" componentclass="ul">
          {games.map(user => <CustomComponent key={user.id}>{user}</CustomComponent>)}
        </ListGroup>
        <div>
          <PaginationComponent page={page} count={count} limit={limit} setPage={setPage}/>
        </div>
      </div>
    );
}

export default GamePage;