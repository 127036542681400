import React from "react";
import Header from "./layout/Header";
import { useState, useEffect, useLayoutEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import Card from "./event/Card";
import userEvent from "@testing-library/user-event";
import { getEvents } from "../api/Event";
import EventCard from "./event/EventCard";

const MainPage = ({socket}) => {
    const [events, setEvents] = useState([]);
    document.title = "Главная";

    useEffect(() => {
        refreshEvents();
    }, []);

    const refreshEvents = () => {
        getEvents(10, 0).then((data) => {
            setEvents(data);
        });
    };

    return (
        <div>
            <Header eventRefreshHandler={refreshEvents} socket={socket}/>
            <div className="d-flex justify-content-center">
                <div className="col-12 col-lg-8 p-4">
                    <h1 className="mb-4">Ближайшие события</h1>
                    <div>
                        <Row xs={1} sm={2} md={3} lg={3} xl={3}>
                            {events.map((eventCard) => (
                                <Col className="mb-4" key={eventCard.id}>
                                    <EventCard name={eventCard.name} user={eventCard.User} desc={eventCard.desc} image={eventCard.image} id={eventCard.id} />
                                </Col>
                            ))}
                        </Row>
                        {events.length === 0 ? <div className="d-flex justify-content-center align-items-center text-center text-muted fs-5">Ближайших событий не обнаружено</div> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainPage;
