import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './style.css';

const ShowMessageWindow = () => {
    return (
        <div id="show-message-window" className="show-message-window">Пожалуйста напишите больше текста для проверки окна</div>
    )
}

export default ShowMessageWindow;