import { apiClient } from "./ApiConfig";

export async function postCreateEvent(name, desc, image, gameId) {
    const path = "/event/create";
    const formData = new FormData();
    formData.append("image", image);
    formData.append("name", name);
    formData.append("desc", desc);
    formData.append("gameId", gameId);

    const data = await apiClient.post(path, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
    );
    if (data.data.user){
        return data.data.event;
    }
}

export async function getEvents(limit, offset, name){
    const res = (await apiClient.get("/events", {
        params: {
            limit: limit,
            offset: offset,
            name: name
        }
    }));

    res.count = res.headers["x-total-count"];
    return res.data;
}

export async function getEvent(id) {
    const path = "/event/"+id;
    const res = await apiClient.get(path);
    return res.data;
}

export async function removeEvent(id) {
    const path ="/event/remove/"+id;
    const res = await apiClient.get(path);
    return res.data;
}

export async function changeEventFieldSort(eventid, aid, bid){
    const path = "/event-field/change-sort";

    const res = await apiClient.post(path, {
        aid: aid,
        bid: bid,
        eventid: eventid
    });

    return res;
}

export async function changeEventRowSort(eventid, aid, bid){
    const path = "/event-row/change-sort";

    const res = await apiClient.post(path, {
        aid: aid,
        bid: bid,
        eventid: eventid        
    })

    return res;
}

export async function postEventField({id, value}){
    const path = "/event-field/edit";

    const res = await apiClient.post(path, {
        id: id,
        value: value
    })

    return res;
}

export async function putEventRow({id, value, rowId, fieldId, sideId}){
    const path = "/event-row/edit";

    const res = await apiClient.put(path, {
        id: id,
        value: value,
        fieldId: fieldId,
        rowId: rowId,
        sideId: sideId
    })

    return res;
}

export async function getRemoveEventRow(id){
    const path = "/event-row/remove/"+id;

    const res = await apiClient.get(path);
    return res;
}

export async function getRemoveEventField(id){
    const path = "/event-field/remove/"+id;

    const res = await apiClient.get(path);
    return res;
}

export async function postCreateEventField(eventId, value){
    const path = "/event-field/create";
    
    const res = await apiClient.post(path, {
        eventId: eventId,
        value: value
    });
    return res;
}

export async function postCreateEventRow(eventId){
    const path = "/event-row/create";

    const res = await apiClient.post(path, {
        eventId: eventId
    });
    return res;
}

export async function putEditEvent({id, name, descFull, desc, dateStart, access, confirmation, changeside}){
    const path = "/event/edit/"+id;

    const res = await apiClient.put(path, {
        id: id,
        name: name,
        desc: desc,
        descFull: descFull,
        dateStart: dateStart,
        access: access,
        confirmation: confirmation,
        changeside: changeside
    });
    return res;
}

export async function putImageEvent(id, image) {
    const path = "/event/edit/"+id;
    const formData = new FormData();
    formData.append("image", image);

    const res = await apiClient.put(path, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
    );
    return res;
}

export async function postImageBrif(file){
    const path = "/event/load-brid-image";
    const formData = new FormData();
    formData.append("image", file);
    
    const res = await apiClient.post(path, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
    );
    return res;
}

export async function putSideEdit({id, name, color}){
    const path = "/event/side/"+id;
    const res = await apiClient.put(path, {
        id: id,
        name: name,
        color: color
    });

    return res;
}

export async function getAddSide({id}){
    const path = "/event/add-side/"+id;
    const res = await apiClient.get(path);

    return res;
}

export async function deleteSide({id}){
    const path = "/event/side/"+id;
    const res = await apiClient.delete(path);

    return res;
}

export async function postCopyRow(id){
    const path = "/event-row/copy/"+id;
    const res = await apiClient.post(path);

    return res;
}

export async function putJoin(rowId){
    const path = "/event-row/join/"+rowId;
    const res = await apiClient.put(path);

    return res;
}

export async function deleteLeave(id){
    const path = "/event-row/leave/"+id;
    const res = await apiClient.delete(path);
    return res;
}

/**
 * 
 * @param {*} id - row id
 */
export async function deleteKick(id){
    const path = "/event-row/kick/"+id;
    const res = await apiClient.delete(path);
    return res;
}

export async function putConfirm(rowId, userId){
    const path = `/event-row/${rowId}/confirm/${userId}`;
    const res = await apiClient.put(path);
    return res;
}

export async function deleteCancel(rowId, userId){
    const path = `/event-row/${rowId}/cancel/${userId}`;
    const res = await apiClient.delete(path);
    return res;
}
