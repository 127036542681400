import React, { useRef } from 'react';
import { useState, useEffect, useLayoutEffect} from "react";
import { useDrag, useDrop } from 'react-dnd';
import customFieldToText from './Util.js';
import { 
    changeEventFieldSort,
    } from '../../api/Event';

function HeaderDiv({field, handleContextMenuOpen, handlerRefresh, handleColumnClick}){
    const ref = useRef(null);

    useEffect(() => {
        drag(drop(ref));
    });

    const [, drop] = useDrop({
        accept: 'header',
        drop: (item) => {
            changeEventFieldSort(item.field.EventId, item.field.id, field.id).then(() => {
                handlerRefresh();
            });
        },
        hover(item, monitor) {
          if (!ref.current) {
            return;
          }
        },
      });

    const [{ isDragging }, drag] = useDrag({
        type: 'header',
        item: { field },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    })

    return (
        <div className='col border-bottom p-2 h-100 d-flex flex-row' 
            ref={ref} 
            item={field} 
            key={field.id} 
            onClick={(e) => {if (customFieldToText(field.name) == field.name) {handleColumnClick(field.name, field.id, 'column')}}} 
            onContextMenu={(e) => {handleContextMenuOpen(e, (customFieldToText(field.name) == field.name), 'column', field.id)}}
            style={{ cursor: customFieldToText(field.name) == field.name ? 'pointer' : 'default' }}
            >
            <span className='align-self-center'>{customFieldToText(field.name)}</span>
        </div>
    );
}

function HeaderTable({fields, handleContextMenuOpen, handlerRefresh, handleColumnClick}) {
    const result = fields.map((field) => 
        <HeaderDiv key={field.id} field={field} handleColumnClick={handleColumnClick} handleContextMenuOpen={handleContextMenuOpen} handlerRefresh={handlerRefresh}></HeaderDiv>
    )
    return (<div className='row div-table-row'>
                <div className='col div-table-col border-bottom p-2 h-100 d-flex flex-row'>
                    <span className='align-self-center'>Участник</span>
                </div>
                {result}
                <div className='col div-table-col border-bottom p-2 h-100 d-flex flex-row'></div>    
            </div>);
}

export default HeaderTable;
