import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postCreate } from "../../../api/User";
import { setToken } from "../../../storage/Token";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import errorParser from "../../../utils/ErrorParser";

const Create = () => {
    document.title = "Регистрация";
    const navigate = useNavigate();

    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordRepeatError, setPasswordRepeatError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let doPost = true;
        setPasswordRepeatError("");
        setNameError("");
        setEmailError("");
        setPasswordError("");

        const data = new FormData(event.currentTarget);

        const name = data.get("name");
        const email = data.get("email");
        const password = data.get("password");
        const passwordRepeat = data.get("passwordRepeat");

        if (password !== passwordRepeat) {
            setPasswordRepeatError("Пароли должны совпадать");
            doPost = false;
        }

        if (doPost) {
            postCreate(name, email, password)
                .then((res) => {
                    setToken(res.data.token);
                    navigate("/site");
                })
                .catch((err) => {
                    errorParser(err, {
                        name: setNameError,
                        email: setEmailError,
                        password: setPasswordError,
                    });
                });
        }
    };

    return (
        <div className="bg-primary">
            <Container>
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col lg={4} md={6} xs={12}>
                        <Card className="rounded-5 px-4 pt-5 pb-0">
                            <Card.Body>
                                <div className="row justify-content-center mb-5">
                                    <div className="col-6 text-center">
                                        <img src="/images/logo.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="mb-3 mt-md-2 ">
                                    <div className="mb-3">
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control isInvalid={nameError} name="name" type="text" placeholder="Имя пользователя" />
                                                <Form.Control.Feedback type="invalid">{nameError}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control isInvalid={emailError} name="email" type="text" placeholder="Email" />
                                                <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Control isInvalid={passwordError} name="password" type="password" placeholder="Пароль" />
                                                <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Control isInvalid={passwordRepeatError} name="passwordRepeat" type="password" placeholder="Повторите пароль" />
                                                <Form.Control.Feedback type="invalid">{passwordRepeatError}</Form.Control.Feedback>
                                            </Form.Group>

                                            <div className="d-grid">
                                                <Button variant="primary" className="shadow" type="submit" className="text-white rounded-3">
                                                    Создать пользователя
                                                </Button>
                                            </div>
                                        </Form>
                                        <div className="mt-3">
                                            <p className="mb-0  text-center">
                                                <a href={"/login"}  className="text-decoration-none">
                                                    Есть аккаунт, войти.
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );

    /*return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 14,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
            <Typography component="h4" variant="secondary">
                Создать пользователя
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Имя пользователя"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    error={usernameError.length > 0}
                    helperText={usernameError}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={emailError.length > 0}
                    helperText={emailError}
                />                
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Пароль"
                    type="password"
                    id="password"
                    autoComplete="password"
                    error={passwordError.length > 0}
                    helperText={passwordError}
                />    
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="passwordRepeat"
                    label="Повторить пароль"
                    type="password"
                    id="passwordRepeat"
                    autoComplete="repeat-password"
                    error={passwordRepeatError.length > 0}
                    helperText={passwordRepeatError}
                />                   
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Создать
                </Button>                  
            </Box>
            </Box>
        </Container>
    );*/
};

export default Create;
