const FindString = ({findString, setFindString, refreshHandler}) => {
    return (
        <div className="mx-2 d-md-flex align-items-top justify-content-between">
        <h4>Игры</h4>
        <div>
          <div className="d-flex justify-content-end">
            <div className="d-block d-inline-block">
              <div className="form-group">
                <div className="imput-group input-group-sm">
                  <input value={findString} onChange={(e) => {setFindString(e.target.value)}} className="form-control border border-right-0 shadow-none finder_str_input bg-light" placeholder="Поиск"></input>
                </div>
              </div>
            </div>
          </div>
        </div>          
      </div>
    );
}

export default FindString;