import { apiClient } from "./ApiConfig";

export async function getNotificationShort() {
    const path = "/notification";
    const res = await apiClient.get(path);
    return res;
}

export async function getNotifications(limit, offset) {
    const path = "/notification/all";
    const res = (await apiClient.get(path, {
        params: {
            limit: limit,
            offset: offset,
        }
    }));

    res.count = res.headers["x-total-count"];
    return res;    
}

export async function postNotificationRead(id) {
    const path = "/notification/"+id+"/read";
    const res = await apiClient.post(path);
    return res;
}

export async function deleteNotification(id) {
    const path = "/notification/"+id;
    const res = await apiClient.delete(path);
    return res;
}
