import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { postLogin } from "../../../api/User";
import { setToken } from "../../../storage/Token";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import "../../../scss/bootstrap.scss";
import "../../../scss/style.scss";
import "./Login.scss";

const Login = () => {
    const { token } = useParams();
    document.title = "Вход";
    const navigate = useNavigate();

    const [nameError, setNameError] = useState("");
    useEffect(() => {
        if (token !== undefined) {
            setToken(token);
            navigate("/site");
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const data = new FormData(event.currentTarget);

        postLogin(data.get("name"), data.get("password"))
            .then((res) => {
                setToken(res.data.token);
                navigate("/site");
            })
            .catch((err) => {
                if (err.response.data.error !== undefined) {
                    setNameError(err.response.data.error);
                } else {
                    setNameError("Неизвестная ошибка");
                }
            });
    };

    const handleLoginDiscordClick = () => {
        const redirect = encodeURIComponent(process.env.REACT_APP_SERVER + "/discordAuth");
        window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${redirect}&response_type=code&scope=identify`;
    };

    return (
        <div className="bg-primary">
            <Container>
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col lg={4} md={6} xs={12}>
                        <Card className="rounded-5 px-4 pt-5 pb-0">
                            <Card.Body className="">
                                <div className="row justify-content-center mb-5">
                                    <div className="col-6 text-center">
                                        <img src="/images/logo.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="">
                                    <div className="mb-3">
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control isInvalid={nameError} name="name" type="text" placeholder="Имя пользователя" className="rounded-3" />
                                                <Form.Control.Feedback type="invalid">{nameError}</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Control name="password" type="password" placeholder="Пароль" className="rounded-3" />
                                            </Form.Group>

                                            <div className="d-grid">
                                                <Button variant="primary" className="text-white rounded-3" type="submit">
                                                    Войти
                                                </Button>
                                            </div>
                                            <div className="d-grid mt-3">
                                                <Button variant="primary" className="text-white rounded-3" type="button" onClick={handleLoginDiscordClick}>
                                                    <img src="/images/logo-discord.svg" alt="" className="discord me-2"/>
                                                    Войти с Discord
                                                </Button>
                                            </div>
                                        </Form>
                                        <div className="mt-3">
                                            <p className="mb-0  text-center">
                                                <a href={"/create"} className="text-decoration-none">
                                                    Создать аккаунт
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
