import Header from "../../layout/Header";
import List from "./List";

const Notifications = ({socket}) => {
    return (
        <div>
            <Header socket={socket} />
            <List />
        </div>
    );
}

export default Notifications;