const LodingIndicator = ({ loading }) => {
    const progressClass = () => {
        return "bg-primary";
    };
    
    const containerStyle = {
        height: "5px",
        display: "fixed",
        left: "0px",
        right: "0px",
        position:"fixed",
        transition: "opacity 0.5s"
    };

    const containerStyleOpacity = {
        opacity: (loading) ? 1 : 0
    };
    
    return (
        <div className="progress rounded-0" style={{...containerStyle, ...containerStyleOpacity}}>
            <div className={"progress-bar progress-bar-striped progress-bar-animated " + progressClass()} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}></div>
        </div>
    );
};

export default LodingIndicator;