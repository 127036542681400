import { useAsyncEffect, useReactive, useSetState } from "ahooks";
import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { getUsers } from "../../../api/User";
import { useNavigate } from "react-router-dom";
import LodingIndicator from "../../../component/layout/LodingIndicator";
import PaginationComponent from "../components/PaginationComponent";
import FindString from '../components/FindString';

  const UserPage = ({setLoading}) => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);    
    const navigate = useNavigate();

    useEffect(() => {
      navigate('/admin/user', {replace: true});

      const fetch = async () => { 
        setLoading(true);
        const res = await getUsers(limit, (page-1)*limit); 
        setUsers(res.data);
        setCount(res.count);
        setLoading(false);
        return;
      }

      fetch();
    },[page]);

    function CustomComponent({children}) {
      let avatar = "/images/avatar-min.png";

      if (children.avatar != null){
        avatar = process.env.REACT_APP_SERVER+children.avatar;
      }

      return (
          <li className="list-group-item" onClick={() => {}}>
            <img className="me-2 rounded-circle shadow" style={{width: '30px', height: '30px'}} src={avatar}></img>
            <span>{children.name}</span>
          </li>
      );
    }

    return (
      <div className="mt-4">
        <FindString />
        <ListGroup className="mt-3" componentclass="ul">
          {users.map(user => <CustomComponent key={user.id}>{user}</CustomComponent>)}
        </ListGroup>
        <div>
          <PaginationComponent page={page} count={count} limit={limit} setPage={setPage}/>
        </div>        
      </div>
    );
}

export default UserPage;