import axios from "axios";
import { getToken } from "../storage/Token";

const baseUrl = () => {
    //const host = window.location.host;
    console.log(process.env.REACT_APP_SERVER);
    const url = process.env.REACT_APP_SERVER;
    return url;
};

export const apiClient = axios.create({
    baseURL: baseUrl(),
});

// Добавьте интерцептор для запросов
apiClient.interceptors.request.use(
    (config) => {
      const token = getToken();
  
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        //config.headers["Content-Type"] = 'application/json';
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  export default apiClient;