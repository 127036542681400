function customFieldToText(fieldname){
    let result = fieldname;
    switch ( fieldname ) {
        case 'role':
            result = 'Роль';
            break;
        case 'side':
            result = 'Сторона';
            break;
    }
    return result;
}

export default customFieldToText;