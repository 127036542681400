import React, { useState } from 'react';
import ReactLoading from "react-loading";

const Loading = ({ show }) => {
    return (
        <div>
            {show ? (
                <div className='my-4 d-flex justify-content-center'>
                    <ReactLoading type="spin" height={100} width={50} />
                </div>
            ) : null}
        </div>
    )
}

export default Loading;