import React, { useEffect, useLayoutEffect } from "react";
import Header from "../../layout/Header";
import { useState } from "react";
import { getUser } from "../../../api/User";
import { Form, Button } from "react-bootstrap";
import { putUser } from "../../../api/User";

const Profile = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [discordBlock, setDiscordBlock] = useState(0);
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [avatarUrl, setAvatarUrl] = useState("");
    //const [avatar, setAvatar] = useState('');

    useEffect(() => {
        getUser().then((user) => {
            document.title = "Профиль " + user.name;
            setName(user.name);
            setEmail(user.email);
            setDiscordBlock(user.discordBlock);
            if (user.avatar) {
                setAvatarUrl(process.env.REACT_APP_SERVER + user.avatar);
            } else {
                setAvatarUrl("/images/avatar-min.png");
            }
        });
    }, []);

    const handleChange = (event) => {
        if (event.target.files[0] != undefined) {
            putUser({avatar: event.target.files[0]}).then((user) => {
                if (!user.error) {
                    setAvatarUrl(process.env.REACT_APP_SERVER + user.avatar);
                } else {
                    //вывести ошибку
                }
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setNameError("");
        putUser({name: name})
            .then(() => {})
            .catch((err) => {
                for (const key in err.response.data.error) {
                    switch (key) {
                        case "name":
                            setNameError(err.response.data.error[key]);
                            break;
                    }
                }
            });
    };

    const discordBlockHandler = (event) => {
        if (discordBlock){
            setDiscordBlock(false);
            putUser({discordBlock: false});
        }else{
            setDiscordBlock(true);
            putUser({discordBlock: true});
        }
    }

    return (
        <div>
            <Header />
            <div className="d-flex justify-content-center">
                <div className="col-12 col-lg-8 vh-100 p-4">
                    <h4>Настройки профиля</h4>
                    <hr />
                    <div className="d-flex justify-content-center">
                        <img
                            className="rounded-circle shadow"
                            style={{ width: "150px", height: "150px", cursor: "pointer" }}
                            src={avatarUrl}
                            onClick={(event) => {
                                document.getElementById("file-loader").click();
                            }}
                        />
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="text-center">Имя пользователя</Form.Label>
                            <Form.Control isInvalid={nameError} name="name" value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Имя пользователя" />
                            <Form.Control.Feedback type="invalid">{nameError}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="text-center">Email</Form.Label>
                            <Form.Control disabled isInvalid={emailError} name="name" value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Имя пользователя" />
                            <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
                        </Form.Group>
                        <input className="d-none" id="file-loader" name="avatar" type="file" placeholder="Аватар" onChange={handleChange} />
                        <div className="d-grid">
                            <Button variant="primary" className="shadow" type="submit">
                                Сохранить
                            </Button>
                        </div>
                    </Form>
                    <hr />
                    <div className="form-check form-switch my-3">
                        <input 
                            onChange={discordBlockHandler}
                            className="form-check-input" 
                            type="checkbox" 
                            id="flexSwitchCheckChecked" 
                            checked={discordBlock}/>
                        <label className="form-check-label" for="flexSwitchCheckChecked">
                            Получать уведомления Discord
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
