import React, { useRef } from "react";
import Header from "../layout/Header";
import { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getEvent, removeEvent, postEventField, postCreateEventField, postCreateEventRow, getRemoveEventField, putEventRow, getRemoveEventRow, putEditEvent, putImageEvent, postImageBrif, postCopyRow } from "../../api/Event";
import { getUser } from "../../api/User";
import { Button, Table } from "react-bootstrap";
import ModalConfirm from "../util/ModalConfirm";
import ContextMenu from "./ContextMenu";
//import EditModal from './EditModal';
import { useDrag, useDrop } from "react-dnd";
import io from "socket.io-client";
import errorParser from "../../utils/ErrorParser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import HeaderTable from "./HeaderTable";
import RowTable from "./RowTable";
import "./event.css";
import Accordion from "react-bootstrap/Accordion";
import Sides from "./Sides";
import { isEditable } from "@testing-library/user-event/dist/utils";
import ShowMessage from "../util/ShowMessage/ShowMessage.js";
import Dropdown from "react-bootstrap/Dropdown";
import Loading from "../util/Loading.jsx";
/*const socket = io(process.env.REACT_APP_SOCKET_SERVER, {
    autoConnect: false,
});*/

const Event = ({socket}) => {
    const navigate = useNavigate();
    const fileInput = useRef(null);
    const nameInput = useRef(null);
    const nameDiv = useRef(null);
    const dateDiv = useRef(null);
    const dateInput = useRef(null);
    const descInput = useRef(null);
    const descDiv = useRef(null);
    const descFullInput = useRef(null);
    const descFullDiv = useRef(null);
    const ckEditor = useRef(null);

    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [name, setName] = useState();
    const [desc, setDesc] = useState();
    const [descFull, setDescFull] = useState();
    const [event, setEvent] = useState();
    const [image, setImage] = useState();
    const [dataRows, setDataRows] = useState([]);
    const [eventFields, setEventFields] = useState([]);
    const [sides, setSides] = useState([]);
    const [isCanEdit, setIsCanEdit] = useState(false);
    const [isCanJoin, setIsCanJoin] = useState(false);
    const [isCanDelete, setIsCanDelete] = useState(false);
    const [dateStart, setDateStart] = useState({ year: null, month: null, date: null, hour: null, minute: null });
    const [dateEdit, setDateEdit] = useState();
    const [timeEdit, setTimeEdit] = useState();

    const [user, setUser] = useState();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [typeEditModal, setTypeEditModal] = useState();
    const [valueEditModal, setValueEditModal] = useState("");
    const [idEdit, setIdEdit] = useState(-1);
    const [idRowEdit, setIdRowEdit] = useState();
    const [idFieldEdit, setIdFieldEdit] = useState();

    const [contextMenu, setContextMenu] = useState(null);
    const [contextMenuActions, setContextMenuActions] = useState([]);

    const [nameError, setNameError] = useState();

    const [socketConnected, setSocketConnected] = useState(false);
    const [socketHash, setSocketHash] = useState("");

    const [confirmation, setConfirmation] = useState(0);
    const [changeside, setChangeside] = useState(0);

    const inputRef = React.createRef();

    useEffect(() => {
        refreshTable();
    }, []);

    useEffect(() => {
        socket.engine.on("change-event", (data) => {
            console.log(`CHANGE ${socket.hash} ${data.id} ${data.hash} `);
            
            if (data.hash !== `${socket.hash}` && (event === undefined || event.id === data.id)) {
                console.log('OUTER CHANGE ${event.id} ');
                refreshTable(false);
            }
        });
    }, []);

    const acessName = ["Закрытый", "Открытый", "По ссылке"];

    const emitSocket = async () => {
        if (socket?.engine?.connected && event) {
            socket.engine.emit("change-event", { id: event.id, hash: socket.hash });
        }
    }

    const refreshTable = async (emit = true) => {
        getUser().then((user) => {
            getEvent(id)
                .then((data) => {
                    if (emit){
                        emitSocket();
                    }
                    /** To see the client version */
                    setIsCanEdit(data.isCanEdit);
                    setIsCanJoin(data.isCanJoin);
                    setIsCanDelete(data.isCanDelete);
                    //setIsCanEdit(false);
                    setName(data.name);
                    setEvent(data);
                    setImage(data.image);
                    setEventFields(data.EventFields);
                    setDataRows(data.EventRows);
                    setDescFull(data.descFull);
                    setDesc(data.desc);
                    setSides(data.EventSides);
                    setUser(user);
                    setChangeside(data.changeside);
                    setConfirmation(data.confirmation);
                    let date = new Date(parseInt(data.dateStart) * 1000);
                    const month = `${date.getMonth()}`.padStart(2, "0");
                    const dateD = `${date.getDate()}`.padStart(2, "0");
                    const hour = `${date.getHours()}`.padStart(2, "0");
                    const minute = `${date.getMinutes()}`.padStart(2, "0");
                    setDateStart({
                        year: date.getFullYear(),
                        month: month,
                        date: dateD,
                        hour: hour,
                        minute: minute,
                    });
                    setDateEdit(`${date.getFullYear()}-${month}-${dateD}`);
                    setTimeEdit(`${hour}:${minute}`);
                    setLoading(false);
                })
                .catch((err) => {
                    alert("Непредвиденная ошибка");
                });
        });
    };

    const handlerChangeDate = () => {
        let date = new Date(`${dateEdit} ${timeEdit}`);
        putEditEvent({ id: event.id, dateStart: Math.floor(date.getTime() / 1000) });
        refreshTable();
    };

    function handleChangeAccess(access) {
        putEditEvent({ id: event.id, access: access });
        refreshTable();
    }

    const handleShowDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleDeleteConfirmation = (result) => {
        //setConfirmationResult(result);
        if (result === true) {
            removeEvent(id);
            navigate("/site");
        }
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleColumnClick = (value, id, type, rowId, fieldId) => {
        inputRef.current.focus();
        setTypeEditModal(type);
        if (value != null) {
            setValueEditModal(value);
        } else {
            setValueEditModal("");
        }
        setIdEdit(id);
        setIdRowEdit(rowId);
        setIdFieldEdit(fieldId);
    };

    const handleSaveEdit = (value) => {
        if (value != null) {
            if (typeEditModal === "column") {
                postEventField({ id: idEdit, value: value }).then(() => {
                    refreshTable();
                });
            }

            if (typeEditModal === "row") {
                if (idEdit !== null) {
                    putEventRow({ id: idEdit, value: value }).then(() => {
                        refreshTable();
                    });
                } else {
                    putEventRow({ value: value, rowId: idRowEdit, fieldId: idFieldEdit }).then(() => {
                        setTypeEditModal("row");
                        refreshTable();
                    });
                }
            }
        }
    };

    const handleRemoveColumn = (id) => {
        getRemoveEventField(id).then(() => {
            refreshTable();
        });
    };

    const handlerCreateRow = () => {
        postCreateEventRow(event.id).then(() => {
            refreshTable();
        });
    };

    /** Всплывающее меню */
    const handleContextMenuOpen = (e, editable, type, id) => {
        e.preventDefault();
        const x = e.clientX;
        const y = e.clientY;
        let contextElements = [];
        if (type == "column") {
            contextElements = [
                {
                    name: "Добавить колонку",
                    action: () => {
                        handleContextMenuClose();
                        postCreateEventField(event.id, "Новый столбец").then(() => {
                            refreshTable();
                        });
                    },
                },
            ];
            if (editable) {
                contextElements.push({
                    name: "Удалить колонку",
                    action: () => {
                        handleContextMenuClose();
                        handleRemoveColumn(id);
                    },
                });
            }
        }
        if (type == "row") {
            contextElements = [
                {
                    name: "Добавить строку",
                    action: () => {
                        handleContextMenuClose();
                    },
                },
                {
                    name: "Копировать строку",
                    action: () => {
                        handleContextMenuClose();
                        postCopyRow(id)
                            .then(() => {
                                refreshTable();
                            })
                            .catch((err) => {
                                ShowMessage({ type: "danger", message: "Не удалось скопировать строку." });
                            });
                    },
                },
                {
                    name: "Удалить строку",
                    action: () => {
                        getRemoveEventRow(id).then(() => {
                            refreshTable();
                        });
                        handleContextMenuClose();
                    },
                },
            ];
        }
        setContextMenuActions(contextElements);
        setContextMenu({ x, y });
    };

    const handleContextMenuClose = (e) => {
        setContextMenu(null);
    };

    const handleEditEvent = ({ name, descFull, desc }) => {
        putEditEvent({ id: event.id, name: name, descFull: descFull, desc: desc })
            .then((data) => {
                setNameError(null);
                refreshTable();
            })
            .catch((err) => {
                showErrors(err);
            });
    };

    const handlerEditImage = (e) => {
        const file = e.target.files[0];
        putImageEvent(event.id, file).then((data) => {
            refreshTable();
        });
    };

    const showErrors = (err) => {
        errorParser(err, {
            name: setNameError,
        });
    };

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        postImageBrif(file)
                            .then((res) => {
                                resolve({ default: `${process.env.REACT_APP_SERVER}${res.data}` });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            },
        };
    }

    function handlerChangeSide(rowId, sideId) {
        putEventRow({ rowId: rowId, sideId: sideId }).then(() => {
            refreshTable();
        });
    }

    function handlerOptionChange({ target: { checked, id, name } }) {
        if (name == "changeside") {
            setChangeside(checked);
            putEditEvent({ id: event.id, changeside: checked });
        }
        if (name == "confirmation") {
            setConfirmation(checked);
            putEditEvent({ id: event.id, confirmation: checked });
        }
    }

    return (
        <div>
            <Header socket={socket}/>
            <Loading show={loading}/>
            {isCanEdit ? <ModalConfirm show={showDeleteModal} handleClose={handleCloseDeleteModal} message="Вы уверены, что хотите удалить событие?" onConfirm={handleDeleteConfirmation} /> : null}

            {!loading ? (
                <div className="d-flex justify-content-center">
                    {contextMenu && <ContextMenu x={contextMenu.x} y={contextMenu.y} onClose={handleContextMenuClose} actions={contextMenuActions} />}
                    <div className="col-12 col-lg-8 p-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1
                                className="mb-4"
                                ref={nameDiv}
                                style={isCanEdit ? { cursor: "pointer" } : {}}
                                onClick={(e) => {
                                    if (isCanEdit) {
                                        nameInput.current.style.display = "block";
                                        nameDiv.current.style.display = "none";
                                        nameInput.current.focus();
                                    }
                                }}
                            >
                                {name}
                            </h1>
                            <div>
                                <input
                                    type="text"
                                    ref={nameInput}
                                    style={{ display: "none" }}
                                    value={name}
                                    className="form-control"
                                    placeholder="Наименование"
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            nameDiv.current.style.display = "block";
                                            nameInput.current.style.display = "none";
                                            handleEditEvent({ name: name });
                                        }
                                    }}
                                    onBlur={(e) => {
                                        nameDiv.current.style.display = "block";
                                        nameInput.current.style.display = "none";
                                        handleEditEvent({ name: name });
                                    }}
                                />
                                <div className="invalid-feedback d-block">{nameError}</div>
                            </div>
                            {isCanEdit && isCanDelete ? (
                                <button className="btn btn-danger" id="eventName" onClick={handleShowDeleteModal}>
                                    Удалить
                                </button>
                            ) : null}
                        </div>
                        <div className="d-grid gap-2 mb-2">
                            <Button variant="primary" onClick={handlerCreateRow}>
                                Завершить ивент
                            </Button>
                        </div>
                        <div style={{ maxHeight: "200px", overflow: "hidden", display: "flex", alignItems: "center" }} className="rounded">
                            {isCanEdit && image ? (
                                <div>
                                    <img
                                        onClick={(e) => {
                                            fileInput.current.click();
                                        }}
                                        src={process.env.REACT_APP_SERVER + image}
                                        style={{ objectFit: "cover", width: "100% ", cursor: "pointer" }}
                                    />
                                </div>
                            ) : (
                                <img src={process.env.REACT_APP_SERVER + image} style={{ objectFit: "cover", width: "100% " }} />
                            )}
                        </div>
                        {isCanEdit && image === undefined ? (
                            <div className="d-grid gap-2 w-100">
                                <Button
                                    variant="primary"
                                    onClick={(e) => {
                                        fileInput.current.click();
                                    }}
                                >
                                    Добавить изображение
                                </Button>
                            </div>
                        ) : null}
                        <input ref={fileInput} type="file" className="d-none" onChange={handlerEditImage} />
                        <h4 className="mt-4">Дата и время начала:</h4>
                        <div
                            ref={dateDiv}
                            style={isCanEdit ? { cursor: "pointer" } : {}}
                            onClick={(e) => {
                                if (isCanEdit) {
                                    dateInput.current.style.display = "block";
                                    dateDiv.current.style.display = "none";
                                }
                            }}
                        >
                            {dateStart.date !== "NaN" ? (
                                <div>
                                    {dateStart.date}.{dateStart.month}.{dateStart.year} {dateStart.hour}:{dateStart.minute}
                                </div>
                            ) : (
                                <div>Не указано</div>
                            )}
                        </div>
                        <div ref={dateInput} style={{ display: "none" }}>
                            <input className="form-control mb-2" type="date" value={dateEdit} onChange={(e) => setDateEdit(e.target.value)} />
                            <input className="form-control" type="time" value={timeEdit} onChange={(e) => setTimeEdit(e.target.value)} />
                            <div className="d-grid gap-2 mt-2">
                                <Button
                                    variant="primary"
                                    onClick={(e) => {
                                        handlerChangeDate();
                                        dateInput.current.style.display = "none";
                                        dateDiv.current.style.display = "block";
                                    }}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                        {isCanEdit ? (
                            <div>
                                <h4 className="mt-4">Доступ:</h4>
                                <div className="d-grid gap-2">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic" className="w-100 text-start">
                                            {acessName[event.access]}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="w-100">
                                            {acessName.map((name, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={(e) => {
                                                        handleChangeAccess(index);
                                                    }}
                                                >
                                                    {name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        ) : null}
                        <h4 className="mt-4">Краткое описание:</h4>
                        <div
                            ref={descDiv}
                            style={isCanEdit ? { cursor: "pointer" } : {}}
                            onClick={(e) => {
                                if (isCanEdit) {
                                    descDiv.current.style.display = "none";
                                    descInput.current.style.display = "block";
                                    descInput.current.focus();
                                }
                            }}
                        >
                            <div>{desc}</div>
                        </div>
                        <textarea
                            ref={descInput}
                            className="form-control"
                            rows="5"
                            type="text"
                            value={desc}
                            style={{ display: "none" }}
                            onChange={(e) => setDesc(e.target.value)}
                            onBlur={(e) => {
                                descDiv.current.style.display = "block";
                                descInput.current.style.display = "none";
                                handleEditEvent({ desc: desc });
                            }}
                            //onKeyDown={(e) => {if (e.key === 'Enter') {descDiv.current.style.display = 'block'; descInput.current.style.display = 'none'; handleEditEvent({desc: desc}); }}}
                        />

                        {isCanEdit ? (
                            <div>
                                <h4 className="mt-4">Настройки:</h4>
                                <div className="form-check">
                                    <input name="changeside" className="form-check-input" id="changeside-checkbox" checked={changeside} onChange={handlerOptionChange} type="checkbox" />
                                    <label className="form-check-label" htmlFor="changeside-checkbox">
                                        Нельзя сменить сторону
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input name="confirmation" className="form-check-input" id="confirmation-checkbox" checked={confirmation} onChange={handlerOptionChange} type="checkbox" />
                                    <label className="form-check-label" htmlFor="confirmation-checkbox">
                                        Подтверждение участия
                                    </label>
                                </div>   
                                <div>
                                    <h4 className="mt-4">Стороны:</h4>
                                    <Sides eventId={id} sides={sides} isEditable={isEditable} handlerRefreshTable={refreshTable} />
                                </div>
                            </div>
                        ) : null}

                        <Accordion defaultActiveKey={null} className="mt-4">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h4 className="mt-2">Основной брифинг</h4>
                                </Accordion.Header>
                                <Accordion.Body className="bf-light">
                                    <div ref={descFullInput} style={{ display: "none" }}>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            ref={ckEditor}
                                            data={descFull}
                                            config={{
                                                extraPlugins: [uploadPlugin],
                                            }}
                                            //onBlur={(e, editor) => {handleEditEvent({descFull: editor.getData()}); descFullInput.current.style.display = 'none'; descFullDiv.current.style.display = 'block'}}
                                            //onKeyDown={(e, editor) => {if (e.key === 'Enter') {handleEditEvent({descFull: editor.getData()});}}}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDescFull(data);
                                            }}
                                        />
                                        <div className="d-grid gap-2 mt-2">
                                            <Button
                                                variant="primary"
                                                onClick={(e) => {
                                                    handleEditEvent({ descFull: descFull });
                                                    descFullInput.current.style.display = "none";
                                                    descFullDiv.current.style.display = "block";
                                                }}
                                            >
                                                Сохранить
                                            </Button>
                                        </div>
                                    </div>
                                    <div
                                        ref={descFullDiv}
                                        style={isCanEdit ? { cursor: "pointer", display: "block" } : {}}
                                        onClick={(e) => {
                                            if (isCanEdit) {
                                                descFullInput.current.style.display = "block";
                                                descFullDiv.current.style.display = "none";
                                                ckEditor.current.editor.focus();
                                            }
                                        }}
                                        className="brifing"
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: descFull && descFull.length > 0 ? descFull : "Не указан" }} />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <hr className="my-4" />

                        {isCanEdit ? (
                            <div className="mb-3">
                                <input
                                    ref={inputRef}
                                    type="text"
                                    value={valueEditModal}
                                    onChange={(e) => {
                                        setValueEditModal(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSaveEdit(valueEditModal);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        handleSaveEdit(valueEditModal);
                                    }}
                                    className="form-control"
                                    placeholder=""
                                />
                            </div>
                        ) : null}
                        <div className="d-grid gap-2 m-3 div-table">
                            <HeaderTable handleColumnClick={handleColumnClick} fields={eventFields} handleContextMenuOpen={handleContextMenuOpen} handlerRefresh={refreshTable} sides={sides}/>
                            {dataRows.map((row) => (
                                <RowTable user={user} sides={sides} key={row.id} row={row} eventFields={eventFields} handleColumnClick={handleColumnClick} handlerRefresh={refreshTable} handleContextMenuOpen={handleContextMenuOpen} isCanEdit={isCanEdit} isCanJoin={isCanJoin} handlerChangeSide={handlerChangeSide} />
                            ))}
                        </div>
                        {isCanEdit ? (
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={handlerCreateRow}>
                                    Добавить строку
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}

        </div>
    );
};

export default Event;
