import React, { useEffect, useState } from "react";
import { getNotifications, deleteNotification, postNotificationRead } from "../../../api/Notification";
import moment from 'moment';
import { FaTrash } from 'react-icons/fa6';
import "./List.scss";
import PaginationComponent from "../../../component/PaginationComponent";
import { useNavigate } from "react-router-dom";

const List = () => {
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const limit = 25;

    useEffect(() => {
        updateNotification();
    },[]);

    const updateNotification = () => {
        getNotifications(limit, (page-1) * limit).then((res) => {
            //console.log(data);
            setNotifications(res.data);
            setCount(res.count);
        })        
    }

    const readHandler = (id, url) => {
        postNotificationRead(id);
        navigate(url);
    }

    const deleteHandler = (id) => {
        deleteNotification(id).then((res) => {
            updateNotification();
        });
    }

    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-lg-8 p-4">
                <h1 className="mb-4">Уведомления</h1>
                {notifications.map(notify => (
                    <div 
                        onClick={(e) => {readHandler(notify.id, notify.url)}}
                        key={notify.id}
                        className={`d-block m-2 border rounded-2 ${notify.status === 'normal' ? 'border-success' : notify.status === 'danger' ? 'border-danger' : 'border-warning'}`} 
                        style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>
                            <div className="m-2 mx-3 d-flex justify-content-between align-items-center">
                                <div>
                                    {notify.message}
                                    <p className="mb-1 fw-lighter" style={{fontSize: "0.7rem"}}>{moment(notify.createdAt).format('DD.MM.YYYY HH:mm')}</p>
                                </div>
                                <div className="round-div"
                                    onClick={(e) => {e.preventDefault(); deleteHandler(notify.id); return false;}}
                                ><FaTrash /></div>
                            </div>
                    </div>
                ))}
                <PaginationComponent limit={limit} page={page} count={count} setPage={setPage} />
            </div>
        </div>
    );
}

export default List;