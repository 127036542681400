import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({ show, handleClose, message, onConfirm }) => {
    const handleConfirm = () => {
        onConfirm(true); // Передача результата подтверждения (true)
        handleClose(); // Закрытие модального окна
    };

    const handleCancel = () => {
        onConfirm(false); // Передача результата отмены (false)
        handleClose(); // Закрытие модального окна
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>Подтверждение</Modal.Header>
            <Modal.Body>
                <p className="mb-0">{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleCancel} className="text-white">
                    Отменить
                </Button>
                <Button variant="primary" onClick={handleConfirm} className="text-white">
                    Подтвердить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
