import { apiClient } from "./ApiConfig";
import { getToken } from "../storage/Token";

export async function postCreate(name, email, password) {
    const path = "/user/create";
    return apiClient.post(path, {
        'name': name,
        'email': email,
        'password': password,
    });
}

export async function postLogin(loginname, password) {
    return apiClient.post("/user/login", {
        'name': loginname,
        'password': password,
    })
}

export async function getUser() {
    return (await apiClient.get("/user")).data.user;
}

export async function getUsers(limit, offset){
    const res = (await apiClient.get("/users", {
        params: {
            limit: limit,
            offset: offset,
        }
    }));
    
    res.count = res.headers["x-total-count"];
    return res;
}

/**
 * Put user data to API
 * Avatar loading separately
 * @date 9/18/2023 - 11:46:12 PM
 *
 * @export
 * @async
 * @param {*} avatar
 * @param {*} name
 * @returns {unknown}
 */
export async function putUser({avatar, name, discordBlock}){
    if (avatar != undefined){
        const formData = new FormData();
        formData.append("image", avatar);
        try {
            const data = await apiClient.put("/user", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            if (data.data.user){
                return data.data.user;
            }
        } catch (err){
            return err.responce.data;
        }
    }
    else{
        return (await apiClient.put("/user", {
            params: {
                name: name,
                discordBlock: discordBlock
            }
        }));
    }    
}