export const getToken = () => {
    const token = localStorage.getItem('react-logic-token');
    return token;
}
  
export const setToken = (token) => {
    localStorage.setItem('react-logic-token', token);
}

export const dropToken = () => {
    localStorage.removeItem('react-logic-token');
}