const ShowMessage = ({type, delay, message}) => {
    const timer = (delay) ? delay : 3000;
    const mainDom = document.getElementById('show-message-window');
    mainDom.innerHTML = message;
    mainDom.classList.remove('danger');
    mainDom.classList.remove('success');
    switch (type){
        case 'danger':
            mainDom.classList.add('danger');
            break;
        case 'sucess':
            mainDom.classList.add('sucess');
            break;
    }
    mainDom.classList.add('show');
    setTimeout(() => {        
        mainDom.classList.remove('show');
        //mainDom.style.display = 'none';
    }, timer);
}
export default ShowMessage;