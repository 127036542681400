import Modal from "react-bootstrap/Modal";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { postCreateEvent } from "../../api/Event";
import { getGames } from "../../api/Game";
import ErrorParser from "../../utils/ErrorParser";

const CreateEventModal = ({ showModal, handleCloseModal, eventRefreshHandler }) => {
    const [nameError, setNameError] = useState("");
    const [descError, setDescError] = useState("");
    const [games, setGames] = useState([]);

    useEffect(() => {
        getGames(20, 0).then((data) => {
            setGames(data.data);
        });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const name = data.get("name");
        const desc = data.get("desc");
        const image = data.get("image");
        const gameId = data.get("gameId");
        postCreateEvent(name, desc, image, gameId)
            .then(() => {
                eventRefreshHandler();
                handleCloseModal();
                setNameError();
            })
            .catch((err) => {
                ErrorParser(err, {
                    name: setNameError,
                    desc: setDescError,
                });
            });
    };

    return (
        <Modal size="lg" backdrop="static" show={showModal} onHide={handleCloseModal} centered contentClassName="rounded-4">
            <Modal.Header closeButton>Создать новое событие</Modal.Header>
            <Modal.Body>
                <Form id="event-form" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Проект</Form.Label>
                        <Form.Select name="gameId">
                            {games.map((game) => (
                                <option value={game.id} key={game.id}>
                                    {game.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center">Наименование</Form.Label>
                        <Form.Control isInvalid={nameError} name="name" type="text" placeholder="Наименование" />
                        <Form.Control.Feedback type="invalid">{nameError}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">Краткое описание</Form.Label>
                        <Form.Control as="textarea" rows={3} isInvalid={descError} name="desc" type="text" placeholder="Краткое описание" />
                        <Form.Control.Feedback type="invalid">{descError}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center" name="image">
                            Загрузить обложку
                        </Form.Label>
                        <Form.Control type="file" name="image" />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="rounded-bottom-4 border-top-0 p-0">
                <Button variant="primary" className="w-100 rounded-bottom-4 rounded-top-0 py-3 m-0 text-white" type="submit" form="event-form">
                    Создать
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateEventModal;
